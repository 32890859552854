.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('./NotFound.png');
    background-size: cover;
    border-radius: 0.7rem;
  }
  
  .not-found-title {
    text-align: center;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-image {
    width: 100%;
    height: auto;
  }
  
  .not-found-content button {
    font-size: 1.2rem; 
    font-weight: bold; 
    color: rgb(50, 50, 50); 
    overflow: hidden; 
    text-overflow: ellipsis; 
    padding: 0.36rem 0.75rem;
    border-radius: 0.7rem; 
    border: 0.05rem solid grey; 
  }

  .not-found-content button:hover {
    background-color: grey;
    cursor: pointer;
  }
  