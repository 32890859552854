.sideComponents-container {
    margin: 2%;
    padding: 2%;
    border: 0.1rem solid #ccc;
    border-radius: 0.7rem;
  }

  .sideComponents-container.night-mode {
    /* Night mode styles */
    background-color: #222;
    color: #fff;
  }
  
  .search-results-container {
    margin: 2%;
    padding: 2%;
    border-radius: 0.7rem;
    border: 0.1rem solid #ccc;
    transition: border-color 0.2s; /* Add a transition for a smooth effect */
  }
  
  .search-results-container:hover {
    border: 0.1rem solid rgb(50,50,50);
    cursor: pointer;
  }
  
  .highlight {
    background-color: argb(32,178,170,0.75); 
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .search-results-container span{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .sideComponents-title {
    text-align: center;
  }
  
  .sideComponents-content {
    text-align: justify;
    margin-top: 0.2rem;
  }
  
  .sideComponents-content h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .sideComponents-content p {
    margin-bottom: 0.2rem;
  }

  .sideComponents-content ul {
    padding-left: 2%; /* Adjust the desired indentation value */
  }
  
  .sideComponents-content li {
    margin-bottom: 0.5rem; /* Adjust the spacing between bullet points */
  }

  .contact-links {
    margin-left: 2%;
  }

  .content-image-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .content-image-landscape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
    height: 0;
    padding-bottom: 28%; 
    border-radius: 0.5rem;
  }
  
  .content-image-portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: min(50%, calc(100vw / 3));
    height: 0;
    padding-bottom: min(89%, calc(100vh - 2rem)); 
    border-radius: 0.5rem;
  }
  
  /* Media query for screens smaller than 720px */
  @media screen and (max-width: 719px) {
    .sideComponents-content {
      text-align: left;
    }
  
    .content-image-landscape {
      width: 96%;
      padding-bottom: 54%;
    }
    
    .content-image-portrait {
      width: 96%;
      padding-bottom: 170%;
    }
  }
