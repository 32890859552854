.destination-container {
  margin: 2%;
  padding: 2%;
  border: 0.1rem solid #ccc;
  border-radius: 0.7rem;
}

.destination-container h2 {
  text-align: center;
}

.destination-content {
  text-align: justify;
  margin-top: 2%;
}

.destination-select {
  margin-top: 2%;
  width: 100%;
  padding: 2%;
  border-radius: 0.7rem;
  border: 0.1rem solid #ccc;
  font-size: 0.75rem;
}

.selected-destination {
  margin-top: 2%;
  font-size: 0.75rem;
  text-align: center;
}

.separator {
  margin-top: 2rem;
}

.content-image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-image-landscape {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 50%;
  height: 0;
  padding-bottom: 28%; 
  border-radius: 0.5rem;
}

.content-image-portrait {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: min(50%, calc(100vw / 3));
  height: 0;
  padding-bottom: min(89%, calc(100vh - 2rem)); 
  border-radius: 0.5rem;
}

/* Media query for screens smaller than 720px */
@media screen and (max-width: 719px) {
  .destination-content {
    text-align: left;
  }

  .content-image-landscape {
    width: 96%;
    padding-bottom: 54%;
  }
  
  .content-image-portrait {
    width: 96%;
    padding-bottom: 170%;
  }
}
