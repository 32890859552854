.featured-destinations {
  background-color: #f8f8f8;
  margin: 2%;
  text-align: center;
  border-left: 0.1rem solid #ccc;
  border-right: 0.1rem solid #ccc;
  border-radius: 0.7rem;
}

.featured-destinations.night-mode {
  /* Night mode styles */
  background-color: #222;
  color: #fff;
}

.featured-destinations h2 {
  font-size: 1.1rem;
  font-weight: bold;
}

.destination-list {
  /* Use CSS grid to center the destination cards */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the minmax values as needed */
  gap: 2rem; 
  justify-items: center;
  justify-content: center;
}

.destination-card {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.7rem;
  border: 0.1rem solid transparent;
  transition: border-color 0.2s;
}

.destination-card:hover {
  border: 0.1rem solid #ccc;
  cursor: pointer;
}

.destination-card img {
  width: 12remrem; /* Set the desired width */
  height: 12remrem; /* Set the desired height */
  object-fit: cover; /* Crop and scale the image while maintaining the aspect ratio */
  border-radius: 0.7rem;
  display: block; /* Set images as block elements */
  margin: auto; /* Center the images horizontally */
}