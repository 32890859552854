.search-form {
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    background: #fff;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
  }
  
  
  .search-icon:focus {
    outline: none;
  }
  
  .search-form input[type="text"] {
    padding: 0.5rem;
    width: 100%;
    border-radius: 0.7rem;
    border: 0.05rem solid #ccc;
    font-size: 0.75rem;
  }
  
  .search-form button {
    padding: 0.5rem 0.5rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 0.7rem;
    font-size: 0.75rem;
    cursor: pointer;
  }
  
  .search-form button:focus {
    outline: none;
  }
  
  