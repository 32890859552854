.tipsPage-container {
    margin: 2%;
    padding: 2%;
    border: 0.1rem solid #ccc;
    border-radius: 0.7rem;
  }
  
  .tipsPage-container h2{
    text-align: center;
  }
 
  .tipsPage-content {
    text-align: justify;
    margin-top: 2%;
  }
  
  .highlighted-text {
    background-color: lightseagreen;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  /* Add styling for the tipsPage */
  .tipsPage-content h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .tipsPage-content p {
    margin-bottom: 0.2rem;
  }

  .tipsPage-content ul {
    padding-left: 0.2rem;
  }
  
  .tipsPage-content li {
    margin-bottom: 0.5rem;
  }

  .highlight {
    background-color: lightseagreen;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .content-image-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .content-image-landscape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 50%;
    height: 0;
    padding-bottom: 28%; 
    border-radius: 0.5rem;
  }
  
  .content-image-portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: min(50%, calc(100vw / 3));
    height: 0;
    padding-bottom: min(89%, calc(100vh - 2rem)); 
    border-radius: 0.5rem;
  }
  
  /* Media query for screens smaller than 720px */
  @media screen and (max-width: 719px) {
    .tipsPage-content {
      text-align: left;
    }
  
    .content-image-landscape {
      width: 96%;
      padding-bottom: 54%;
    }
    
    .content-image-portrait {
      width: 96%;
      padding-bottom: 170%;
    }
  }
  