.DestinationPageParent {
  margin: 2%;
  border: 0.1rem solid #ccc;
  border-radius: 0.7rem;
}

.DestinationPageParent h2 {
  text-align: center;
  font-size: 1.1rem; 
  font-weight: bold; 
}

.destination-list-dp {
  /* Use CSS grid to center the destination cards */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust the minmax values as needed */
  gap: 2rem; 
  justify-items: center;
  justify-content: center;
}

.destination-card {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countrySelector {
  color: transparent;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border-width: 0;
  border-radius: 0.7rem;
  border-color: transparent;
}

.destination-card img {
  width: 12rem; /* Set the desired width */
  height: 12rem; /* Set the desired height */
  object-fit: cover; /* Crop and scale the image while maintaining the aspect ratio */
  border-radius: 0.7rem;
  display: block; /* Set images as block elements */
  margin: auto; /* Center the images horizontally */
}

.ad-column {
  position: absolute;
  background-color: rgb(170, 172, 172);
  width: 160px;
  display: flex;
  flex-wrap: wrap;
  right: 0;
  height: 100%;
  top: 0;
}

