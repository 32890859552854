.nav-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%; 
}

.nav-links li {
  margin: 0.5rem;
  position: relative;
  flex-basis: 10%; 
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.35rem 0.35rem;
  border-radius: 0.7rem; 
  border: 0.05rem solid transparent;
}

.nav-links a:hover {
  color: rgb(246,210,183);
  border: 0.05rem solid rgb(246,210,183);
  cursor: pointer;
}

.nav-links .active {
  color: #fff; /* Add a different color for active link */
}

/* Add background color */
body {
  background-color: rgb(50, 50, 50);
}
