body {
  font-family: "Roboto Mono", monospace;
  background-color: #f8f8f8;
}

.night-mode {
  background-color: #222;
  color: #fff;
}


.content-wrapper {
  display: flex;
  justify-content: flex-start; /* Adjust the justification as needed */
}

.content-column {
  flex: 1 1 auto;
  width: 100%; /* Subtract the width of the vertical ad area yani böyle: calc(100% - 180px) */
  display: flex;
  flex-direction: column;
  
}
