.comment-section {
    margin-top: 2rem;
    width: 100%;
  }


  
  .comment-section h4 {
    font-size: 1.5rem;
    text-align: center;
  }
  
  .comment-box {
    position: relative;
    border: 0.05rem solid #ccc;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 2rem;
    width: fit-content;
    min-width: 30rem;
    resize: vertical;
  }
  
  .comment-box span {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 0.75rem;
    color: grey;
  }
  
  .CommentBoxText {
    margin-bottom: 2rem;
  }

  .subcommentSubmit {
    text-align: right;
  }

  .commentSubmit {
    text-align: right;
  }

  .commentContainer,
  .subcommentContainer {
    display: flex;
    flex-direction: column;
  }
  
  .commentTextarea,
  .subcommentTextarea {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  
  .commentTextarea textarea,
  .subcommentTextarea textarea {
    flex: 1;
    width: 100%;
    height: 3rem;
    resize: vertical;
    padding: 1rem;
    border: 0.1rem solid #ccc;
    border-radius: 0.7rem;
    margin-bottom: 0.5rem;
  }
  
  .commentTextareaButton,
  .subcommentTextareaButton {
    justify-content: flex-end;
  }
  
  .subcommentVisible {
    background-color: rgb(180,180,180);
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    text-align: right;
  }
  
  .subcommentTextarea {
    display: flex;
    align-items: flex-end;
    width: 100%;
    position: relative; /* Add this to make the positioning relative */
  }
  
  .closeSubcommentButton {
    color: grey;
    border-radius: 50%;
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .subcommentSubmit button {
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
    border: none;
    cursor: pointer;
    background-color: rgb(180,180,180);
    border: none;
    cursor: pointer;
  }

  .commentSubmit button {
    padding: 0.5rem 1rem;
    border-radius: 0.7rem;
    border: none;
    cursor: pointer;
    background-color: rgb(180,180,180);
    border: none;
    cursor: pointer;
  }

  .subcommentSubmit button:hover {
    
    background-color: rgb(246,210,183);
    border: none;
    cursor: pointer;
  }

  .commentSubmit button:hover {
    
    background-color: rgb(246,210,183);
    border: none;
    cursor: pointer;
  }
  
  .comment-box button:hover {
    
    background-color: rgb(246,210,183);
    border: none;
    cursor: pointer;
  }
  
  .administrator-badge {
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 0.7rem;
    background-color: transparent;
  }
  
  .administrator-badge img {
    width: 2rem;
    height: auto;
    object-fit: scale-down;
  }

  /* Floating alert styles */
.alert {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  z-index: 9999;
}

.alert-success {
  background-color: #4caf50;
}

.alert-error {
  background-color: #f44336;
}

.custom-alert-danger {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  z-index: 9999;
  background-color: #ffc107;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.subcommentCaptcha,
.commentCaptcha {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;
}

.subcommentCaptchaInput,
.commentCaptchaInput {
  justify-content: flex-end;
  width: 2.75rem;
}

.commentCaptcha input{

  width: 2.75rem;
  resize: horizontal;
  padding: 0.5rem 1rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.7rem;
    
}

.subcommentCaptcha input{
 
  width: 2.75rem;
  resize: horizontal;
  padding: 0.5rem 1rem;
  border: 0.1rem solid #ccc;
  border-radius: 0.7rem;
  
}

.subcommentCaptcha p,
.commentCaptcha p {
  margin-right: 1rem; /* Adjust as needed */
}


  
  /* Media Queries for Responsive Design */
  
  @media only screen and (max-width: 768px) {
    .comment-box {
        width: fit-content;
        min-width: 10rem;
    }
  
    .commentTextarea textarea,
    .subcommentTextarea textarea {
      height: 4rem;
    }

    .subcommentCaptcha textarea,
    .commentCaptcha textarea{
      width: 100%;  
    }
  }
  
  @media only screen and (max-width: 576px) {
    .comment-box {
        width: fit-content;
        min-width: 8rem;
    }
  
    .commentTextarea textarea,
    .subcommentTextarea textarea {
      height: 5rem;
    }

    .commentTextareaButton,
    .subcommentTextareaButton {
      width: 100%;
    }

    .subcommentCaptcha textarea,
    .commentCaptcha textarea{
      width: 100%;  
    }
  }
  