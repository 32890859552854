.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;
  color: #fff;
  padding: 1%;
  min-height: 10vh;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.logo img {
  height: 4rem;
  width: auto;
}

.navigation {
  height: 100%;
}

.navigation p{
  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;
}

.search {
  height: 100%;
}

.nav button {
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem; 
}

.nav button:hover {
  color: rgb(246,210,183);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem; 
}


@media (max-width: 540px) {
  .logo img {
    height: 3rem;
  }

  .nav {
    font-size: 1rem;
  }

  .nav button {
    font-size: 2rem; 
  }

}

