.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-image: url('./hero-image.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vw * 2.5);
  max-height: 38rem;
}

.hero-image{
  width: 100vw;
  height: auto;
  margin: 0;
  padding: 0;
  position: absolute; 
  top: 0; 
  left: 50%; 
  transform: translateX(-50%); 
  z-index: -1; 
}

.hero-content{
  position: relative;
}

.hero-content h1 {
    font-size: 2.5rem; 
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.3);
    color: black;
    padding: 0.7rem 1.5rem;
    border-radius: 1.5rem;
  }


  .hero-content h1:hover {
    color: dimgray;
    cursor: pointer;
  }

@media (max-width: 540px) {
  .hero-content h1 {
    font-size: 1.5rem;
    width: min-content;
    border: double;
    color: black;
    padding: 1.5rem;
    border-radius: 50%;
  }
}
